import Controller from "../framework/alto/data/controller";
import sectorwatch from "../images/sectorwatch.png";
import pinnacleracing from "../images/pinnacleracing.png";
// import altojs from "../images/alto.svg";

let portfolioController = Controller({

    collection: [{
        title: 'Sector Watch',
        id: '01',
        alt: 'sector watch icon',
        img: sectorwatch,
        url: 'http://chadeubanks.com/sectors/'
    },{
        title: 'Pinnacle Racing',
        id: '02',
        alt: 'pinnacle racing icon',
        img: pinnacleracing,
        url: 'https://pinnacleracing.io'
    }]

});

/*
,{
        title: 'Alto.js',
        id: '03',
        alt: 'alto.js icon',
        img: altojs,
        url: 'http://altojs.com'
    }
 */

export default portfolioController;