import React, {Fragment} from 'react';
import LabelView from "../framework/alto/ui/label_view";
import ImageView from "../framework/alto/ui/image_view";
import css from "./demo_cell.module.css"
const DemoCell = ({
                      data
                  }) => {
    return (
        <Fragment>
            <a href={data.url} className={css.link}>
                <ImageView className={css.demoIcon} src={data.img} alt={data.alt}/>
                <LabelView className={css.title} title={data.title}/>
            </a>
        </Fragment>
    )
};

export default DemoCell;