import React, {Fragment} from 'react'
import css from "./portfolio.module.css";
import LabelView from "../framework/alto/ui/label_view";
import View from "../framework/alto/ui/view";
import ListView from "../framework/alto/ui/list_view";
import DemoCell from "../components/demo_cell";
import "../common_styles/common.css";
import navigation from "../images/navigation.png";
import ImageView from "../framework/alto/ui/image_view";
import { Link } from 'gatsby'
import useController from "../framework/alto/data/useController";
import portfolioController from "../controllers/portfolio_controller";

const PortfolioPage = () => {
    const [data] = useController(portfolioController);

    return (
        <Fragment>
            <View className={css.document}>
                <Link to="/" className={css.linkHome}>
                    <ImageView className={css.navigation} src={navigation} alt={'navigation'}/>
                </Link>
                <LabelView className={css.title} title={'Portfolio'}/>
                <ListView className={css.listview} data={data.collection}>
                    <DemoCell />
                </ListView>
            </View>
        </Fragment>
    )
}

export default PortfolioPage
